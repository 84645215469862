<template>
  <div>
    <v-alert :value="balanceAdjustment && list.length >= 10" type="warning" class="my-2">{{ $t('balance_adjustments.adjustment_limit_reached') }}</v-alert>
    <item-list
      :listId="table"
      :list="availablePackages"
      :availableHeaders="headers"
      class="mt-2"
    >
      <template v-slot:price="{ item }">
        <span v-if="localCurrency" class="grey--text text--darken-1 font-weight-medium">
          {{formatAmount(getPriceAmount(item))}} {{localCurrency.name}}
        </span>
        <span v-if="foreignCurrency" class="grey--text font-weight-medium font-weight-light ml-2">
          {{formatAmount(applyCurrencyExchange(getPriceAmount(item), foreignCurrency.id))}} {{foreignCurrency.name}}
        </span>
      </template>
      <template v-slot:appendActions="{ item }">
        <v-layout wrap align-center>
          <tooltip-button
            @click="amount = { item, quantity: amount(item) - 1 }"
            :disabled="(balanceAdjustment ? getCurrentAmount(item) : 0) + amount(item) - 1 < 0"
            :disabledTooltip="true"
            color="primary"
            icon="mdi-minus"
            small
            dark
            fab
          />
          <v-text-field
            :value="amount(item) + (balanceAdjustment ? getCurrentAmount(item) : 0)"
            @input="(value) => amount = { item, quantity: parseInt(formatAmount(value) - (balanceAdjustment ? getCurrentAmount(item) : 0)) }"
            @keypress="onlyInt"
            :error="accountLimitExceeded(item) || dailyLimitExceedingPackages.includes(item.id)"
            hide-details
            class="ml-0 mr-2 center-textfield pt-0"
            autocomplete="off"
            type="number"
            :max="getMaximumOnInput(item)"
            min="0"
          />
          <tooltip-button
            @click="amount = { item, quantity: amount(item) + 1 }"
            :disabled="disableAdd(item)"
            :disabledTooltip="!disableAdd(item)"
            :tooltipText="dailyLimitExceeded(item) ? $t('packages.daily_limit_exceed') : $t('packages.account_limit_exceed')"
            color="primary"
            icon="mdi-plus"
            small
            dark
            fab
          />
        </v-layout>
      </template>
    </item-list>
  </div>
</template>

<script>
import Vue from 'vue'
import routeNames from '@/router/routes'
import { mapState, mapGetters } from 'vuex'
import moneyFormat from '@/mixins/MoneyFormat'
import pricingHelpers from '@/mixins/PricingHelpers'
import localizationMixins from '@/mixins/Localization'
import { getTokensInAccount } from '@/utils/GuestUtils'
import { sanitize } from '@/utils/TextFormatUtils'

export default {
  props: {
    search: String,
    extendedAccount: Object,
    storeModule: String,
    balanceAdjustment: Boolean,
    list: Array
  },
  data () {
    return {
      table: 'Packages',
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('fields.name'), path: 'name', show: true, getValue: (item) => this.localize({ array: item.name }), class: "grey--text text--darken-1 font-weight-medium" },
        { text: this.$t('fields.price'), path: 'price', show: false },
        {
          path: 'current',
          text: this.$t('packages.fields.current'),
          getValue: (item) => this.getCurrentAmount(item),
          class: "grey--text text--darken-1 font-weight-medium",
          show: true
        },
        {
          path: 'max',
          text: this.$t('packages.fields.max'),
          getValue: (item) => this.getMaximumOnAccount(item) || null,
          class: "grey--text text--darken-1 font-weight-medium",
          show: true
        },
        {
          text: this.$t('packages.fields.current_daily'),
          path: 'currentAvailableProducts',
          getValue: (item) => item.mustBeBooked ? this.getBookedProducts(item) + item.currentAvailableProducts : null,
          show: false,
          class: 'grey--text text--darken-1 font-weight-medium'
        },
        {
          text: this.$t('packages.fields.max_daily'),
          path: 'maxDailyProducts',
          getValue: (item) => item.mustBeBooked ? item.maxDailyProducts : null,
          show: false,
          class: 'grey--text text--darken-1 font-weight-medium'
        }
      ],
      getTokensInAccount
    }
  },
  computed: {
    ...mapState(['packages', 'guests']),
    ...mapState({
      dailyLimitExceedingPackages: state => state.packageOrder.bookingErrorPackages
    }),
    ...mapGetters({
      localCurrency: 'configuration/localCurrency',
      foreignCurrency: 'configuration/foreignCurrency',
      getBookedPackages: 'packageOrder/getBookedPackages',
      getPackage: 'packages/getPackage',
      isAccountLimitExceeded: 'packageOrder/isAccountLimitExceeded'
    }),
    pricingGroupId () {
      return this.extendedAccount ? this.extendedAccount.account.pricingGroupId : null
    },
    accountCurrencies () {
      return this.extendedAccount ? this.extendedAccount.currencies : []
    },
    availablePackages () {
      return this.packages.list
        .filter((pkg) => {
          return typeof this.getPriceAmount(pkg) === 'number' &&
            (this.search ? sanitize(this.localize({ array: pkg.name })).includes(this.search) : true)
        })
    },
    amount: {
      get () {
        return ({ id }) => {
          return this.$store.getters[`${this.storeModule}/getPackageAmount`](id)
        }
      },
      set ({ item, quantity }) {
        this.$store.commit(`${this.storeModule}/setPackageQuantity`, { packageId: item.id, quantity: Number.isNaN(quantity) ? 0 : quantity })
      }
    }
  },
  methods: {
    getPriceAmount ({ id }) {
      const pkg = this.packages.list.find((item) => item.id === id)
      if (!pkg || !this.extendedAccount) return null
      const price = this.getPrice(pkg.pricingGroupsWithPrices, this.pricingGroupId)
      return price ? price.price : null
    },
    disableAdd (pkg) {
      if (this.balanceAdjustment) return false
      const amount = getTokensInAccount(this.accountCurrencies, pkg) + (this.amount(pkg) * pkg.tokenAmount)
      return (
        typeof pkg.maxTokenAmountOnAccount === 'number' &&
        (amount + pkg.tokenAmount) > pkg.maxTokenAmountOnAccount
      ) || this.dailyLimitExceeded(pkg)
    },
    getMaximumOnAccount (pkg) {
      return pkg.maxTokenAmountOnAccount / pkg.tokenAmount
    },
    getMaximumOnInput (pkg) {
      if (this.balanceAdjustment) return null
      const values = []
      if (typeof pkg.maxTokenAmountOnAccount === 'number') {
        values.push(this.getMaximumOnAccount(pkg))
      }
      if (typeof pkg.currentAvailableProducts === 'number') {
        values.push(pkg.currentAvailableProducts + this.getBookedProducts(pkg))
      }
      return Math.min(...values)
    },
    getCurrentAmount (pkg) {
      return parseInt(getTokensInAccount(this.accountCurrencies, pkg) / pkg.tokenAmount)
    },
    getCartAmount (pkg) {
      return this.amount(pkg) / pkg.tokenAmount
    },
    dailyLimitExceeded (pkg) {
      return typeof pkg.currentAvailableProducts === 'number' &&
        this.getCartAmount(pkg) + 1 > (
          pkg.currentAvailableProducts +
          (this.balanceAdjustment ? 0 : this.getBookedPackages({ packageId: pkg.id, accountId: this.extendedAccount.account.id }))
        )
    },
    getBookedProducts (pkg) {
      return this.balanceAdjustment
        ? 0
        : this.getBookedPackages({ packageId: pkg.id, accountId: this.extendedAccount.account.id })
    },
    accountLimitExceeded (pkg) {
      return this.storeModule === 'packageOrder' && this.isAccountLimitExceeded({ pkg, curr: this.getCurrentAmount(pkg) })
    }
  },
  mixins: [
    localizationMixins,
    pricingHelpers,
    moneyFormat
  ],
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    LoadingField: () => import('@/components/app-components/LoadingField.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue')
  },
  name: 'PackageList',
  beforeDestroy () {
    this.$store.commit('packageOrder/resetBookingErrorPackages')
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .center-textfield {
    width: 100px;
    input {
      padding-left: 10px;
      color: #757575;
    }
  }
  .v-messages__message {
    margin: 8px 0px !important;
  }
}
</style>
